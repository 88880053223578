import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const PostContent = styled.div`
  * {
    font-size: 19px;
    line-height: 28px;
    font-family: Raleway, sans-serif;
    font-weight: 300;
    color: #776262;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 38px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  a {
    color: #00ada7;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  li {
    list-style: inside;
  }

  a:hover {
    color: #00ada7;
    text-decoration: underine;
  }

  img {
    max-width: 100%;
  }

  blockquote {
    border-left: 10px solid #fff;
    padding-left: 15px;
  }

  .gatsby-resp-image-figcaption {
    text-align: center;
  }
`

export const HTMLContent = ({ content, className }) => (
  <PostContent
    className={className}
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

export const Content = ({ content, className }) => (
  <PostContent className={"preview-content"}>{content}</PostContent>
)

HTMLContent.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

export default HTMLContent
