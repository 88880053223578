import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout/Main"
import Content, { HTMLContent } from "../components/Content"
import {
  Wrapper,
  WrapperHeader,
  WrapperMainHeading,
} from "../components/styled/Wrapper"

export const AboutPageTemplate = props => {
  const { title, content, contentComponent } = props
  const PageContent = contentComponent || Content

  return (
    <Wrapper>
      <WrapperHeader>
        <WrapperMainHeading>{title}</WrapperMainHeading>
      </WrapperHeader>

      <PageContent className="content" content={content} />
    </Wrapper>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data, pageContext }) => {
  const aboutMeData = data.allMarkdownRemark.edges[0].node

  return (
    <Layout
      pageContext={{
        page: "about-me",
        ...pageContext,
      }}
    >
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={aboutMeData.frontmatter.title}
        content={aboutMeData.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage {
    allMarkdownRemark(
      limit: 1
      filter: { frontmatter: { templateKey: { eq: "about-page" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
