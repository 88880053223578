import styled from "@emotion/styled"

export const Wrapper = styled.article`
  position: relative;
  top: -60px;
  color: #776262;
  background-color: #ece9e1;
  border: 10px solid #776262;
  padding: 20px;
  margin-bottom: 0;
`

export const WrapperHeader = styled.header`
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #462f2f;
`

export const WrapperMainHeading = styled.h1`
  font-size: 60px;
  font-family: "Dancing Script", cursive;
`
